import React from "react";
import "../style/asset.scss";

export default function Terms() {
  return (
    <div className=" relative asset pb-[313px] ">
      <hgroup className=" mt-[23px] text-[#04FFC9] font-[BebasNeue] text-[187px] font-normal leading-[289px] ">
        Terms of Use
      </hgroup>
      <div className=" m-auto w-[80vw] text-left flex flex-col gap-[40px] ">
        <main>
          <div className=" flex flex-col gap-[64px] pt-10 ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                1. Acceptance of Terms
              </hgroup>
              <p className="!font-normal !mt-5">
                By using the services offered by send.foundation (“We”, “Our”,
                or “Us”), you agree to abide by these Terms of Use. If you do
                not agree to these terms, please refrain from using our
                services.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                2. Modifications
              </hgroup>
              <p className="!font-normal !mt-5">
                We may modify these Terms at any time, and such modifications
                will be effective immediately upon posting the modified Terms on
                the Website. Your continued use of the Website signifies your
                acceptance of such modified Terms.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                3. Use of Services
              </hgroup>
              <p className="!font-normal !mt-5">
                Our services and the content provided are for your personal,
                non-commercial use. You may not modify, distribute, transmit,
                display, reproduce, create derivative works from, or sell any
                information or services obtained from us.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                4. User Conduct
              </hgroup>
              <p className="!font-normal !mt-5">
                You are responsible for the content you share and your
                interactions with others while using our services. Any unlawful
                or disruptive behavior may result in termination of access to
                our services.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                5. Intellectual Property Rights
              </hgroup>
              <p className="!font-normal !mt-5">
                Our content is protected by intellectual property laws.
                Unauthorised use of our content is prohibited.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                6. Limitation of Liability
              </hgroup>
              <p className="!font-normal !mt-5">
                We are not liable for any indirect, incidental, special,
                consequential, or punitive damages arising out of your use of
                our services.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                7. Governing Law
              </hgroup>
              <p className="!font-normal !mt-5">
                These Terms are governed by the laws of New Zealand.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                8. Contact Information
              </hgroup>
              <p className="!font-normal !mt-5">
                For any inquiries, please contact us at <a href="mailto:matt@coseed.io" className="text-[#23D2A1] underline">matt@coseed.io</a>.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                9. Disclaimer
              </hgroup>
              <p className="!font-normal !mt-5">
                We are not financial advisors, accountants, or lawyers. The
                information we provide is not intended as professional advice
                and should not be relied upon as such. Consult with a qualified
                professional for advice regarding your specific situation.
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

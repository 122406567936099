import React from 'react';
import { useNavigate } from "react-router-dom";
import "../style/footer.scss"
import logo from "../images/logo.png"
import bird from "../images/bird.svg"
import In from "../images/in.svg"
import fly from "../images/fly.svg"


const Footer = () => {
  const navigate=useNavigate()
  
  return (
      <div className=' footer pt-[51px] pb-[45px] px-[13vw] flex justify-between items-center '>
        <div className='flex flex-col items-center'>
          <img className=' w-[258px] h-[132px] mb-[30px] ' src={logo} alt="" />
          <p
            className=' py-[16px] px-[32px] rounded-[43px] bg-[#24deaa] text-[#000] text-[20px] leading-[32px] tracking-[2px] cursor-pointer '
            onClick={() => window.location.href = 'mailto:matt@coseed.io'}
          >
            Contact Us
          </p>
        </div>
        <div className=' flex flex-row 2xl:gap-[202px] md:gap-[50px] sm:gap-[50px] -xs:flex-col'>
          <ul className=' flex flex-col gap-[20px] items-start font-bold leading-[150%] tracking-[1.6px]  -xs:items-center'>
            <li className=' text-[#23D2A1] font-bold leading-[150%] tracking-[1.6px] underline '>Sitemap</li>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#partners">Partners</a></li>
          </ul>
          <ul className=' flex flex-col gap-[20px] items-start font-bold leading-[150%] tracking-[1.6px] -xs:mt-5 -xs:items-center'>
            <li className=' text-[#23D2A1] font-bold leading-[150%] tracking-[1.6px] underline '>Help</li>
            <li><a href="/user-agreement">User Agreement</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms">Terms of Use</a></li>
            <li><a href="/disclaimer">Disclaimer</a></li>
          </ul>
          <ul className=' flex flex-col gap-[20px] items-start font-bold leading-[150%] tracking-[1.6px] -xs:mt-5 -xs:items-center'>
            <li className=' text-[#23D2A1] font-bold leading-[150%] tracking-[1.6px] '>Follow</li>
            <li className=' flex items-center gap-[6px] '>
              <img src={bird} alt="" />
              <a href="https://twitter.com/sendnz72">@SENDNZ72</a>
            </li>
            <li className=' flex items-center gap-[6px] '>
              <img src={In} alt="" />
              <a href="https://www.linkedin.com/in/mattarmstrong72/">@mattarmstrong72</a>
            </li>
            {/* <li className=' flex items-center gap-[6px] '>
              <img src={fly} alt="" />
              <a href="#">@link</a>
            </li> */}
          </ul>
        </div>
      </div>
  ); 
};
export default Footer;
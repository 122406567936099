import React from "react";
import "../style/asset.scss";

export default function UserAgreement() {
  return (
    <div className=" relative asset pb-[313px] ">
      <hgroup className=" mt-[23px] text-[#04FFC9] font-[BebasNeue] text-[187px] font-normal leading-[289px] ">
        User Agreement
      </hgroup>
      <div className=" m-auto w-[80vw] text-left flex flex-col gap-[40px] ">
        <main>
          <div className=" flex flex-col gap-[64px] pt-10 ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                1. Introduction
              </hgroup>
              <p className="!font-normal !mt-1">
                1.1 Acceptance of AgreementBy accessing or using the services of
                SEND.foundation ("We," "Us," "Our," "SEND"), you agree to comply
                with and be bound by this User Agreement ("Agreement"). Please
                review it carefully. If you do not agree to this Agreement, you
                are not permitted to use our services.
              </p>
              <p className="!font-normal !mt-1">
                1.2 Modification of AgreementWe reserve the right to modify this
                Agreement at any time, and without prior notice, by posting
                amended terms on this website. Your continued use of the SEND
                services indicates your acceptance of the amended User
                Agreement.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                2. Obligations
              </hgroup>
              <p className="!font-normal !mt-1">
                2.1 Service EligibilityTo use the Services, you must be at least
                16 years old and are not prohibited from receiving services
                under the laws of New Zealand or other applicable jurisdictions.
              </p>
              <p className="!font-normal !mt-1">
                2.2 Your AccountYou are responsible for maintaining the
                confidentiality of your account and password and for restricting
                access to your computer. You agree to accept responsibility for
                all activities that occur under your account or password.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                3. Rights and Limits
              </hgroup>
              <p className="!font-normal !mt-1">
                3.1 Your ContentYou own the content and information that you
                submit or post to SEND, and you are granting us the following
                non-exclusive license: A worldwide, transferable, and
                sublicensable right to use, copy, modify, distribute, publish,
                and process, information and content that you provide without
                any further consent, notice and/or compensation to you or
                others.
              </p>
              <p className="!font-normal !mt-1">
                3.2 Service LimitationsWe may change, suspend, or end any
                Service, or change and modify prices prospectively in our
                discretion.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                4. Disclaimer and Limit of Liability
              </hgroup>
              <p className="!font-normal !mt-1">
                4.1 No WarrantyWe make no representation or warranty about the
                suitability, reliability, availability, timeliness, security, or
                accuracy of the SEND services for any purpose. Application
                programming interfaces (APIs) may not be available at all times.
                To the extent permitted under law, the services are provided "AS
                IS" without warranty or condition of any kind.
              </p>
              <p className="!font-normal !mt-1">
                4.2 Limitation of LiabilityWe shall not be liable for any
                indirect, incidental, special, consequential, or punitive
                damages, or any loss of profits or revenues, whether incurred
                directly or indirectly, or any loss of data, use, goodwill, or
                other intangible losses, resulting from (A) your use or
                inability to use any of our services; (B) any unauthorized
                access to or use of our servers and/or any personal information
                stored therein.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                5. Termination
              </hgroup>
              <p className="!font-normal !mt-1">
                We can each end this Agreement anytime we want. Upon
                termination, you lose the right to access or use the services
                provided by SEND.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                6. Governing Law and Dispute Resolution
              </hgroup>
              <p className="!font-normal !mt-1">
                This Agreement and any dispute arising out of or in connection
                with this Agreement or related in any way to the Site
                ("Dispute") will be governed as to all matters, including, but
                not limited to the validity, construction and performance of
                this Agreement, by and under the laws of New Zealand, without
                giving effect to conflicts of law principles thereof.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                7. General Terms
              </hgroup>
              <p className="!font-normal !mt-1">
                If a court with authority over this Agreement finds any part of
                it unenforceable, you and us agree that the court should modify
                the terms to make that part enforceable while still achieving
                its intent. If the court cannot do that, you and us agree to ask
                the court to remove that unenforceable section and still enforce
                the rest of this Agreement.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                8. Contact Information
              </hgroup>
              <p className="!font-normal !mt-1">
                For any questions regarding this User Agreement or any of our
                services, please contact us at
                <a
                  href="mailto:matt@coseed.io"
                  className="text-[#23D2A1] underline"
                >
                  matt@coseed.io
                </a>
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

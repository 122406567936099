import React from 'react'
import "../style/asset.scss";
import line from "../images/home-line.png";
import topbg from "../images/topbg.png"
import bottombg from "../images/bottombg.png"
import assetRight1 from "../images/assetRight1.png"
import assetRight2 from "../images/assetRight2.png"
import assetLeft from "../images/assetLeft.png"

export default function Asset() {
  return (
    <div className=" relative asset pb-[313px] ">
      <hgroup className=" mt-[23px] text-[#04FFC9] font-[BebasNeue] text-[187px] font-normal leading-[289px] ">
      Launch a Digital asset
      </hgroup>
      <div className=" flex flex-col items-center gap-[40px] w-[1029px] m-auto mb-[111px] ">
        <p className=" text-[32px] leading-[150%] tracking-[3.2px] ">
        The Comprehensive Guide to Launching a<br /> Digital Asset-Integrated Business
        </p>
        <img src={line} alt="" />
        <section className=" w-[530px] leading-[150%] tracking-[1.6px] ">
        In the digital era, businesses are exploring the potential of digital assets, which can be represented by tokens or NFTs, to revolutionise traditional operations. This guide offers a roadmap for businesses aiming to seamlessly incorporate a digital asset component.
        </section>
      </div>
      <div className=" m-auto w-[80vw] text-left flex flex-col gap-[40px] ">
        <main>
          <div className="asset-title mb-[32px] ">
            <h2>Incubation</h2>
            <h1>Phase</h1>
          </div>
          <div className=" flex flex-col gap-[64px] pl-[198px] ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Outcome Efficiency
              </hgroup>
              <p>In the inception stage, determine how the integration of a digital asset will amplify your business outcome. How will the digital asset streamline operations or offer unparalleled value to users?</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Advisory Agreement
              </hgroup>
              <p>Partner with industry experts. An advisory agreement ensures that seasoned professionals guide your digital vision, offering their invaluable expertise.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Project Management Methodology
              </hgroup>
              <p>Success hinges on effective management. Adopt a methodology that resonates with your business goals, be it Agile, Scrum, or any other structured approach.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Role of the Project Director
              </hgroup>
              <p>Depending on the venture's complexity, a dedicated project director might be indispensable. Their mandate? To orchestrate the entire digital asset integration, ensuring adherence to benchmarks and upholding quality.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Business Model & Positioning
              </hgroup>
              <p>Craft a distinct space for your brand. Understand your value proposition, analyse rivals, and solidify your position in the digital asset and crypto marketplace.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Legal Aspects and Compliance
              </hgroup>
              <p>Navigate legalities with precision. Grasp the implications of your digital asset usage, select an ideal jurisdiction for your business, understand KYC mandates, and formulate requisite legal documents.</p>
            </div>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
        <main>
          <div className="asset-title mb-[32px] ">
            <h1>Raise </h1>
            <h1>Phase</h1>
          </div>
          <div className=" flex flex-col gap-[64px] pl-[198px] ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Strategic Investor Outreach
              </hgroup>
              <p>Zero in on potential investors, from venture capitalists to blockchain foundations. Develop a compelling pitch deck that underscores your business model and the financial strategies built around digital assets.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Legal Structuring
              </hgroup>
              <p>Chalk out an optimal business blueprint, especially when considering digital asset and equity raises. Be cognisant of tax implications and prioritise compliance.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Human Resources & Hiring
              </hgroup>
              <p>People drive vision. Identify pivotal roles, recruit top-tier talent, and ensure they're aligned with the company's digital aspirations.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Awareness Building
              </hgroup>
              <p>Engage with potential clients and the wider digital community. Harness social media, ambassador initiatives, and varied channels to stoke interest and excitement.</p>
            </div>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
        <main>
          <div className="asset-title mb-[32px] ">
            <h1>Launch</h1>
            <h1>Phase</h1>
          </div>
          <div className=" flex flex-col gap-[64px] pl-[198px] ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Resourcing & Marketing
              </hgroup>
              <p>Ramp up for the grand launch. Strategically allocate resources, enlist the right marketing teams, and ensure content dissemination is on point.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Digital Asset Preparation & Distribution
              </hgroup>
              <p>Seal your digital asset framework, devise necessary contracts, and map out the distribution strategy. This step is pivotal to ensure the assets reach their intended recipients effectively.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Post-launch Activities
              </hgroup>
              <p>Forge a bond with the community, address any teething issues post-launch, and maintain open lines of communication with investors and stakeholders.</p>
            </div>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
        <main>
          <div className="asset-title mb-[32px] ">
            <h2>Growth & </h2>
            <h2>Handover</h2>
          </div>
          <div className=" flex flex-col gap-[64px] pl-[198px] ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Growth Activities
              </hgroup>
              <p>Think beyond the immediate launch. Strategise for expansion through promotions, community engagement, and liquidity initiatives. Refresh your roadmap and whitepaper periodically to mirror your evolving ethos.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Handover & Remote Support
              </hgroup>
              <p>If transitioning is on the horizon, orchestrate a smooth handover. Facilitate team training, provide unwavering support, and ensure the vision remains undiluted.</p>
            </div>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
      </div>
      <div className=" w-[773px] flex flex-col m-auto gap-[40px] items-center mt-[150px] ">
        <section className=' text-[32px] leading-[150%] tracking-[3.2px] '>Conclusion</section>
        <p className=" text-[16px] leading-[150%] tracking-[1.6px] ">Are you a revenue-positive startup or have completed a successful fundraising round and ready for the next leap? Let SEND be your strategic partner. Together, we build the future.</p>
        <div
          className=" text-[#000] text-[20px] leading-[32px] tracking-[2px] py-[16px] px-[32px] rounded-[43px] bg-[#24DEAA] "
          onClick={() => window.location.href = 'mailto:matt@coseed.io'}
        >
          CONTACT US
        </div>
      </div>
      <img className=" absolute z-[-1] top-[-134px] left-[50%] translate-x-[-50%] " src={topbg} alt="" />
      <img className=" absolute z-[-1] bottom-[134px] right-0 " src={bottombg} alt="" />
      <img className=" desk absolute z-[-1] top-[10%] right-0 " src={assetRight1} alt="" />
      <img className=" desk absolute z-[-1] top-[38%] right-0 " src={assetRight2} alt="" />
      <img className=" desk absolute z-[-1] top-[65%] left-0 " src={assetLeft} alt="" />
    </div>
  );
}

import React from 'react'

export default function TableItem(props:any) {
  return (
    <section id={props.content.id} className=" flex-1 flex p-[24px] flex-col text-left gap-[40px]  ">
      <hgroup className=" flex gap-[16px] items-center ">
        <img className=" p-[10px] " src={props.content.img} alt="" />
        <span className=" text-[24px] font-bold leading-[113%] tracking-[2.4px] uppercase ">{props.content.name}</span>
      </hgroup>
      <p className=" text-[18px] font-medium leading-[150%] ">{props.content.description}</p>
    </section>
  )
}

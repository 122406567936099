import React from "react";
import "../style/startup.scss";
import line from "../images/home-line.png";
import topbg from "../images/topbg.png"
import bottombg from "../images/bottombg.png"
import startupLeft1 from "../images/startupLeft1.png"
import startupLeft2 from "../images/startupLeft2.png"
import startupRight from "../images/startupRight.png"

export default function Startup() {
  return (
    <div className=" relative startup pb-[313px] ">
      <hgroup className=" mt-[23px] text-[#04FFC9] font-[BebasNeue] text-[187px] font-normal leading-[289px] ">
        Launch a Startup
      </hgroup>
      <div className=" flex flex-col items-center gap-[40px] w-[1029px] m-auto mb-[111px] ">
        <p className=" text-[32px] leading-[150%] tracking-[3.2px] ">
          Launching a Successful Startup with SEND: <br />
          The Comprehensive Guide
        </p>
        <img src={line} alt="" />
        <section className=" w-[530px] leading-[150%] tracking-[1.6px] ">
          In today's digital-forward landscape, embarking on a startup journey
          can be both challenging and exhilarating. At SEND, we specialise in
          guiding startups that have demonstrated revenue generation or
          successfully completed a fundraising round. Here's a roadmap designed
          for aspiring entrepreneurs eager to take their startups to the next
          level with us.
        </section>
      </div>
      <div className=" m-auto w-[80vw] text-left flex flex-col gap-[40px] ">
        <main>
          <div className="startup-title mb-[32px] ">
            <h2>1. Qualification</h2>
            <h1>Criteria</h1>
          </div>
          <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] pl-[198px] ">
            <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Before proceeding, ensure your startup meets SEND's primary
              criteria:
            </hgroup>
            <p>Demonstrated positive revenue generation or</p>
            <p>Successful completion of at least one fundraising round.</p>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
        <main>
          <div className="startup-title mb-[32px] ">
            <h2>2. Incubation</h2>
            <h1>Phase</h1>
          </div>
          <div className=" flex flex-col gap-[64px] pl-[198px] ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Outcome Analysis
              </hgroup>
              <p>Evaluate how your product or service addresses market needs and how you've created value for your customers, leading to revenue generation or successful fundraising.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Advisory Collaboration
              </hgroup>
              <p>Leverage SEND's vast network of industry experts. Collaborate with professionals who have been in your shoes and achieved success.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Strategic Planning
              </hgroup>
              <p>Adopt a robust project management approach that aligns with your startup's goals. Whether you lean towards Agile or another method, SEND ensures that you are on the right track.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Role of Project Leadership
              </hgroup>
              <p>As your startup evolves, leadership is paramount. Identify whether you need a dedicated project director or if existing leadership can steer the ship towards your vision.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Business Model Refinement
              </hgroup>
              <p>Fortify your startup's place in the market. Dive deeper into your value proposition, understand your competitors better, and identify unique differentiators.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Financial Health Check
              </hgroup>
              <p>Review your financial models, budgets, and revenue streams. SEND emphasises startups that are revenue-positive or have a successful fundraising history, ensuring you're set for future success.</p>
            </div>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
        <main>
          <div className="startup-title mb-[32px] ">
            <h2>3. Scaling </h2>
            <h1>Phase</h1>
          </div>
          <div className=" flex flex-col gap-[64px] pl-[198px] ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Strategic Outreach
              </hgroup>
              <p>Identify potential growth areas and explore collaboration opportunities. SEND's vast network can open doors to partnerships, alliances, and market expansion.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Legal and Compliance
              </hgroup>
              <p>Stay ahead of legalities. Ensure all regulatory requirements are met and intellectual property, if any, is secured.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Team Expansion and Culture
              </hgroup>
              <p>The right people propel growth. Based on your stage and scaling plans, strategise hiring, nurture talent, and cultivate a growth-minded culture.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Market Awareness and Branding
              </hgroup>
              <p>Make some noise. From creating compelling branding materials to harnessing digital media, position your startup for increased visibility and traction.</p>
            </div>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
        <main>
          <div className="startup-title mb-[32px] ">
            <h2>4. Growth & </h2>
            <h1>Transition Phase</h1>
          </div>
          <div className=" flex flex-col gap-[64px] pl-[198px] ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Post-Fundraising Strategies
              </hgroup>
              <p>With funds in the kitty, chart out a detailed roadmap. Where will investments go? How will they spur growth? SEND aids in creating a clear, actionable strategy.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Engage and Evolve
              </hgroup>
              <p>Forge stronger ties with your consumer base, address feedback, and pivot as required. Continuous improvement is the key.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" text-[24px] leading-[150%] tracking-[2.4px] ">
              Ready for the Next Step
              </hgroup>
              <p>Whether it's another fundraising round, an acquisition, or exploring new markets, SEND ensures you're well-prepared and strategically poised for what's next.</p>
            </div>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
        <main>
          <div className="startup-title mb-[32px] ">
            <h2>5. Continuous </h2>
            <h1>Support from SEND</h1>
          </div>
          <div className=" flex flex-col gap-[64px] pl-[198px] ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <p>While your journey might have milestones, our support is continuous. From mentorship, networking opportunities, to the latest industry insights, we're with you at every step, ensuring your startup doesn't just succeed but thrives.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <p>Are you a revenue-positive startup or have completed a successful fundraising round and ready for the next leap? Let SEND be your strategic partner. Together, we build the future.</p>
            </div>
          </div>
        </main>
        <img className=" w-full " src={line} alt="" />
      </div>
      <div className=" w-[773px] flex flex-col m-auto gap-[40px] items-center mt-[150px] ">
        <p className=" text-[32px] leading-[150%] tracking-[3.2px] ">Are you a revenue-positive startup or have completed a successful fundraising round and ready for the next leap? Let SEND be your strategic partner. Together, we build the future.</p>
        <div
          className=" text-[#000] text-[20px] leading-[32px] tracking-[2px] py-[16px] px-[32px] rounded-[43px] bg-[#24DEAA] "
          onClick={() => window.location.href = 'mailto:matt@coseed.io'}
        >
          CONTACT US
        </div>
      </div>
      <img className=" absolute z-[-1] top-[-134px] left-[50%] translate-x-[-50%] " src={topbg} alt="" />
      <img className=" absolute z-[-1] bottom-[134px] right-0 " src={bottombg} alt="" />
      <img className=" desk absolute z-[-1] top-[20%] right-0 " src={startupRight} alt="" />
      <img className=" desk absolute z-[-1] top-[35%] left-0 " src={startupLeft1} alt="" />
      <img className=" desk absolute z-[-1] top-[55%] left-0 " src={startupLeft2} alt="" />
    </div>
  );
}

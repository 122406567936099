import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../style/header.scss";
import logo from "../images/logo.png";
import message from "../images/message.svg";
import menuHidden from "../images/menu-hidden.svg";
import menuShow from "../images/menu-show.svg";
import menuLine from "../images/menu-line.png";
import launch1 from "../images/header-launch1.svg";
import launch2 from "../images/header-launch2.svg";

const Header = () => {
  const navigate=useNavigate()
  const [show, setShow] = useState(false);
  const showChange = () => {
    setShow(!show);
  };
  const [launch, setLaunch] = useState(false);
  const launchChange = () => {
    setLaunch(!launch);
  };
  return (
    <div className=" relative z-[3] header h-[134px] flex w-full items-center px-[13vw] py-[24px] justify-between ">
      {/* header-left */}
      <img className=" header-logo " src={logo} alt="" />
      {/* header-right */}
      <ul className=" header-desk whitespace-nowrap font-semibold gap-[24px] items-center leading-[32px] tracking-[1.6px] ">
        <li className="p-[16px] cursor-pointer" onClick={()=>navigate('/')}>Home</li>
        <li
          className="p-[16px] relative flex items-center justify-center gap-[10px] cursor-pointer "
          onClick={launchChange}
        >
          <span
            style={{
              color: !launch ? "#fff" : "#28E0AE",
              textDecoration: !launch ? "none" : "underline",
            }}
          >
            Launch
          </span>
          <img
            style={{ display: !launch ? "block" : "none" }}
            className=" p-[10px] "
            src={launch1}
            alt=""
          />
          <img
            style={{ display: launch ? "block" : "none" }}
            className=" p-[10px] "
            src={launch2}
            alt=""
          />
          <div
            style={{ display: launch ? "block" : "none" }}
            className=" absolute flex flex-col gap-[10px] rounded-[10px] bg-[#222828] whitespace-nowrap text-left left-0 top-[77px] "
          >
            <p className=" p-[16px] leading-[32px] tracking-[1.6px] " onClick={()=>navigate('/startup')}>
              Launch a startup
            </p>
            <p className=" p-[16px] leading-[32px] tracking-[1.6px] " onClick={()=>navigate('/asset')}>
              Launch a digital asset
            </p>
          </div>
        </li>
        <li className="p-[16px] cursor-pointer"><a href="#about">About</a></li>
        <li className="p-[16px] cursor-pointer"><a href="#partners">Partners</a></li>
        <li
          className="py-[16px] px-[32px] text-[#000] rounded-[43px] bg-[#24deaa] cursor-pointer "
        >
          <a href="mailto:matt@coseed.io">Contact Us</a>
        </li>
      </ul>
      <ul className=" header-mobile relative gap-[24px] justify-center items-center ">
        <li
          style={{ display: !show ? "block" : "none" }}
          className=" p-[1px] rounded-[8px] cursor-pointer "
        >
          <img src={message} alt="" />
        </li>
        <li
          style={{ display: !show ? "block" : "none" }}
          className=" p-[1px] rounded-[8px] cursor-pointer "
          onClick={() => showChange()}
        >
          <img src={menuHidden} alt="" />
        </li>
        <li
          style={{ display: show ? "block" : "none" }}
          className=" p-[1px] rounded-[8px] bg-[#24deaa] cursor-pointer "
          onClick={() => showChange()}
        >
          <img src={menuShow} alt="" />
        </li>
        <div
          style={{ display: show ? "flex" : "none" }}
          className=" absolute z-[5] w-[100vw] h-[calc(100vh-111px)] flex justify-center bg-[#232323] top-[77px] right-[-13vw] pt-[34px] "
        >
          <ul className=" flex flex-col w-[300px] font-semibold gap-[24px] items-center leading-[32px] tracking-[1.6px] ">
            <li className="p-[16px] cursor-pointer">Home</li>
            <li
              className="p-[16px] flex flex-col items-center justify-center cursor-pointer "
              onClick={launchChange}
            >
              <div className=" flex items-center justify-center gap-[10px] p-[16px] ">
              <span
                style={{
                  color: !launch ? "#fff" : "#28E0AE",
                  textDecoration: !launch ? "none" : "underline",
                }}
              >
                Launch
              </span>
              <img
                style={{ display: !launch ? "block" : "none" }}
                className=" p-[10px] "
                src={launch1}
                alt=""
              />
              <img
                style={{ display: launch ? "block" : "none" }}
                className=" p-[10px] "
                src={launch2}
                alt=""
              />
              </div>
              <div
                style={{ display: launch ? "block" : "none" }}
                className=" flex w-[300px] flex-col gap-[10px] rounded-[10px] bg-[#222828] whitespace-nowrap text-left "
              >
                <p className=" p-[16px] leading-[32px] tracking-[1.6px] ">
                  Launch a startup
                </p>
                <p className=" p-[16px] leading-[32px] tracking-[1.6px] ">
                  Launch a digital asset
                </p>
              </div>
            </li>
            <li className="p-[16px] cursor-pointer"><a href="#about">About</a></li>
            <li className="p-[16px] cursor-pointer"><a href="#partners">Partners</a></li>
            <img src={menuLine} alt="" />
            <li className="py-[16px] px-[32px] text-[#000] rounded-[43px] bg-[#24deaa] cursor-pointer ">
              <a href="mailto:matt@coseed.io">Contact Us</a>
            </li>
          </ul>
        </div>
      </ul>
    </div>
  );
};
export default Header;

import Home from "../views/home"
import Startup from "../views/startup"
import Asset from "../views/asset"
import UserAgreement from "../views/user-agreement"
import PrivacyPolicy from "../views/privacy-policy"
import Terms from "../views/terms"
import Disclaimer from "../views/disclaimer"

const routes = [
    {
        path: '/',
        name: '/',
        til: 'Home',
        element: Home,
        hidden: false,
    },
    {
        path: '/startup',
        name: '/startup',
        til: 'Startup',
        element: Startup,
        hidden: false,
    },
    {
        path: '/asset',
        name: '/asset',
        til: 'Asset',
        element: Asset,
        hidden: false,
    },
    {
        path: '/user-agreement',
        name: '/user-agreement',
        til: 'User Agreement',
        element: UserAgreement,
        hidden: false,
    },
    {
        path: '/privacy-policy',
        name: '/privacy-policy',
        til: 'Privacy Policy',
        element: PrivacyPolicy,
        hidden: false,
    },
    {
        path: '/terms',
        name: '/terms',
        til: 'Terms of Use',
        element: Terms,
        hidden: false,
    },
    {
        path: '/disclaimer',
        name: '/disclaimer',
        til: 'Disclaimer',
        element: Disclaimer,
        hidden: false,
    },
]


export default routes
import React from 'react';
import './App.scss';
import Header from './layout/header';
import Footer from './layout/footer';
import { Route, Routes } from "react-router-dom";
import routes from './router'

function App() {
  return (
    <div className="App w-full text-[#fff] font-[Roboto] text-[16px] font-semibold ">
      <Header />
      <Routes>
        {routes.map((ele) => (
          <Route
            key={ele.name}
            path={ele.path}
            element={<ele.element />}
          ></Route>
        ))}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
import React from 'react'
import cardline from '../images/cardline.svg'

export default function CardItem(props:any) {
  return (
    <section className=" w-[376px] flex py-[32px] px-[42px] flex-col text-left gap-[8px] rounded-[20px] bg-[#1e1d1d] ">
      <hgroup className=" flex gap-[12px] items-center ">
        <img src={props.content.img} alt="" />
        <span className=" leading-[150%] tracking-[1.6px] uppercase ">{props.content.title}</span>
      </hgroup>
      <img className=' relative ml-[60px] w-[230px] ' src={cardline} alt="" />
      <p className=" font-normal leading-[150%] tracking-[1.6px] ">{props.content.description}</p>
    </section>
  )
}

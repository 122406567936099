import "../style/asset.scss";

export default function Disclaimer() {
  return (
    <div className=" relative asset pb-10 ">
      <hgroup className=" mt-[23px] text-[#04FFC9] font-[BebasNeue] text-[187px] font-normal leading-[289px] ">
      Disclaimer
      </hgroup>
      <div className=" m-auto w-[80vw] text-left flex flex-col gap-[40px] ">
        <main>
          <div className=" flex flex-col gap-[64px] pt-10 ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
              Legal Disclaimer
              </hgroup>
              <p className="font-bold !mt-5">Disclaimer of Professional Advice:</p>
              <p className="!font-normal !mt-5">The contents of this Website, including, without limitation, all information, materials, services, suggestions, and advice (the “Content”), are for general informational purposes only and do not constitute professional financial, investment, legal, or tax advice. Send Foundation is not a registered or licensed financial advisor, investment advisor, broker/dealer, or accountant, and we do not offer or provide securities, investments, legal, or tax advice or services.</p>
              <p className="!font-normal !mt-5">Users of this Website should not rely upon the Content as a substitute for independent professional advice and are urged to consult with certified financial advisors, legal counsel, or other qualified professionals before making any investment or financial decisions. Any reliance on the Content is at the user’s own risk.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <p className="font-bold !mt-5">No Offer or Solicitation of Securities:</p>
              <p className="!font-normal !mt-5">Nothing on this Website constitutes an offer, or a solicitation of an offer, to buy or sell securities, or any financial instruments whatsoever. The Content is not intended to be used, and should not be used, as a basis for making investment decisions. Users should conduct their own due diligence and are encouraged to seek advice from a qualified professional advisor before making any investment or financial decisions.</p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <p className="font-bold !mt-5">No Warranty or Liability:</p>
              <p className="!font-normal !mt-5">The Content is provided on an “as-is” and “as-available” basis, with no guarantees of completeness, accuracy, or timeliness, and without warranties of any kind, either express or implied. Send Foundation, its affiliates, agents, or employees shall not be liable for any errors or omissions in the Content or for any actions taken in reliance thereon. Users are responsible for their own due diligence and decisions, and Send Foundation accepts no responsibility for any loss or damage, whether direct or consequential, which arises from reliance on the Content provided on this Website.</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

import React from "react";
import "../style/asset.scss";

export default function PrivacyPolicy() {
  return (
    <div className=" relative asset pb-[313px] ">
      <hgroup className=" mt-[23px] text-[#04FFC9] font-[BebasNeue] text-[187px] font-normal leading-[289px] ">
        Privacy Policy
      </hgroup>
      <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
        Last Updated: [3rd October 2023]
      </hgroup>
      <div className=" m-auto w-[80vw] text-left flex flex-col gap-[40px] ">
        <main>
          <div className=" flex flex-col gap-[64px] pt-10 ">
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                1. Introduction
              </hgroup>
              <p className="!font-normal !mt-5">
                Send Foundation (“we”, “our”, or “us”) is committed to
                protecting the privacy of our users. This Privacy Policy
                outlines how we collect, use, disclose, and protect your
                information when you use our services through send.foundation
                (“Website”).
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                2. Information We Collect
              </hgroup>
              <p className="!font-normal !mt-5">
                We may collect information that you provide directly to us, such
                as your name, email address, and contact information when you
                use our services, participate in our events, or communicate with
                us.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                3. Use of Information
              </hgroup>
              <p className="!font-normal !mt-5">
                We use the information we collect to provide, maintain, and
                improve our services, to develop new services, and to protect
                our users. We may also use the information to communicate with
                you, such as responding to your requests or inquiries.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                4. Information Sharing and Disclosure
              </hgroup>
              <p className="!font-normal !mt-5">
                We do not sell, trade, rent, or otherwise share your personal
                information with third parties without your consent, except as
                required by law or as necessary to provide our services.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                5. Security
              </hgroup>
              <p className="!font-normal !mt-5">
                We employ administrative, technical, and physical safeguards
                designed to protect the information collected by the Website.
                However, no method of transmission or storage is completely
                secure, and we cannot guarantee the absolute security of your
                information.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                6. Your Choices
              </hgroup>
              <p className="!font-normal !mt-5">
                You may have the right to access, correct, or delete your
                personal information held by us. You may also opt-out of
                receiving promotional communications from us. To make such
                requests, contact us at matt@coseed.io.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                7. Changes to This Policy
              </hgroup>
              <p className="!font-normal !mt-5">
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the updated Privacy Policy
                on our Website. It is your responsibility to review this Privacy
                Policy periodically for any changes.
              </p>
            </div>
            <div className=" flex flex-col gap-[24px] leading-[150%] tracking-[1.6px] ">
              <hgroup className=" !text-[24px] leading-[150%] tracking-[2.4px] ">
                8. Contact Us
              </hgroup>
              <p className="!font-normal !mt-5">
                For any questions or concerns regarding this Privacy Policy,
                please contact us at:
                <br />
                Email:{" "}
                <a
                  href="mailto:matt@coseed.io"
                  className="text-[#23D2A1] underline"
                >
                  matt@coseed.io
                </a>
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

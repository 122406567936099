import React, { useState, useEffect } from "react";
import TableItem from "../moudle/tableItem";
import CardItem from "../moudle/cardItem";
import "../style/home.scss";
import homebg from "../images/homebg.png";
import line from "../images/home-line.png";
import homeLogo1 from "../images/home-logo1.png";
import homeLogo2 from "../images/home-logo2.png";
import homeLogo3 from "../images/home-logo3.png";
import homeLogo4 from "../images/home-logo4.png";
import vision1 from "../images/vision1.jpeg";
import vision2 from "../images/vision2.jpeg";
import vision3 from "../images/vision3.png";
import loop1 from "../images/loop1.png";
import loop2 from "../images/loop2.png";
import loop3 from "../images/loop3.png";
import loop4 from "../images/loop4.png";
import left from "../images/chevron-left.svg";
import right from "../images/chevron-right.svg";
import prefessionals from "../images/professionals.png";
import prefessionalsbg from "../images/professionalsbg.png";
import whiteLeft from "../images/white-left.svg";
import whiteRight from "../images/white-right.svg";
import gaming from "../images/gameing.svg";
import nfts from "../images/nfts.svg";
import ai from "../images/AI.svg";
import rwa from "../images/rwa.svg";
import web3 from "../images/web3.png";
import personal from "../images/personal.svg";
import ball from "../images/ball.png";
import service1 from "../images/service1.svg";
import service2 from "../images/service2.svg";
import service3 from "../images/service3.svg";
import service4 from "../images/service4.svg";
import service5 from "../images/service5.svg";
import service6 from "../images/service6.svg";
import partner1 from "../images/partners1.svg";
import partner2 from "../images/partners2.svg";
import partner3 from "../images/partners3.svg";
import partner4 from "../images/partners4.svg";
import greenLeft from "../images/greenLeft.png";
import greenRight from "../images/greenRight.png";

const industries = {
  gaming: {
    id: "gaming",
    img: gaming,
    name: "Gaming",
    description:
      "We are building a gaming launchpad that provides technology resources and funding support to gaming startups, aiming to accelerate their growth and success in the gaming industry",
  },
  nfts: {
    id: "nfts",
    img: nfts,
    name: "NFTs",
    description:
      "With NFTs at all-time lows (ATL), we are optimistic about backing projects seeking to launch their unique collection of profile picture (PFP) and membership NFTs",
  },
  ai: {
    id: "ai",
    img: ai,
    name: "AI",
    description:
      "AI has become an integral part of our daily lives, enhancing experiences through personal assistants, recommendation systems, language translation, navigation, and more, making it crucial for startups to leverage this technology for efficient scaling and staying competitive",
  },
  rwa: {
    id: "rwa",
    img: rwa,
    name: "RWA (Real World Assets)",
    description:
      "We provide tailored programs, mentorship, funding access, and technical expertise to support real-world asset (RWA) projects, positioning them for success in the anticipated bull run and the emerging market",
  },
  web3: {
    id: "web3",
    img: web3,
    name: "web3",
    description:
      "We have worked closely with web3 projects since 2017 helping them launch into the market from idea conception",
  },
  personal: {
    id: "personal",
    img: personal,
    name: "Personal Development",
    description:
      "SEND is currently focusing on the personal development space to bring positive mindset to the masses",
  },
};
const tag = [
  "Design",
  "Team acquisition",
  "Business Development",
  "Fundraising",
  "Development",
  "Digital Marketing",
  "Market making introductions",
  "Technology Build",
  "Audit introductions",
];
const service = {
  one: {
    img: service1,
    title: "1. Outcome efficiency:",
    description:
      "Receive expert advisory support and project management methodology tailored to your project's needs.",
  },
  two: {
    img: service2,
    title: "2. Value proposition & market positioning:",
    description:
      "In-depth competitor review and market positioning to solidify your place in the global crypto market.",
  },
  three: {
    img: service3,
    title: "3. Budget, token use case, tokenomics & raise structuring:",
    description:
      "Receive expert advisory support and project management methodology tailored to your project's needs.",
  },
  four: {
    img: service4,
    title: "4. Branding:",
    description:
      "Access to basic branding services and resources, ensuring your project's strong online presence.",
  },
  five: {
    img: service5,
    title: "5. Pitch deck & litepaper/whitepaper:",
    description:
      "Collaborate on pitch deck creation and litepaper/whitepaper drafting, with advisory input on market fit and narrative.",
  },
  six: {
    img: service6,
    title: "6. Potential investor awareness & feedback:",
    description:
      "Leverage our network for strategic investor selection, including VCs, marketing partners, and exchange partners.",
  },
};

export default function Home() {
  const [screen, setScreen] = useState(0);

  const resizeUpdate = (e: any) => {
    // 通过事件对象获取浏览器窗口的高度
    let w = e.target.innerWidth;
    setScreen(w);
  };

  useEffect(() => {
    // 页面刚加载完成后获取浏览器窗口的大小
    let w = window.innerWidth;
    setScreen(w);

    // 页面变化时获取浏览器窗口的大小
    window.addEventListener("resize", resizeUpdate);

    return () => {
      // 组件销毁时移除监听事件
      window.removeEventListener("resize", resizeUpdate);
    };
  }, []);

  const [trans, setTrans] = useState(1);
  const transAdd = () => {
    if (trans < 3) {
      setTrans(trans + 1);
    } else {
      setTrans(0);
    }
  };
  const transRed = () => {
    if (trans > 0) {
      setTrans(trans - 1);
    } else {
      setTrans(3);
    }
  };
  return (
    <div id="home" className=" flex flex-col w-full items-center ">
      {/* frist part */}
      <div className=" home-frist relative w-full h-[828px] flex flex-col pt-[62px] items-center bg-[#232323] ">
        <img className=" absolute top-[-111px] " src={homebg} alt="" />
        <p className=" text-[24px] leading-[150%] tracking-[2.4px] mb-[63px] ">
          Aspire to
        </p>
        <h1 className=" text-[#04FFC9] font-[BebasNeue] text-[364px] font-normal leading-[289px] mb-[45px] ">
          SEND.
        </h1>
        <div className=" flex flex-col items-center gap-[27px] w-[428px] text-center ">
          <p className=" text-[24px] leading-[150%] tracking-[2.4px] ">
            Sending innovative technology startups since 2017
          </p>
          <div
            className=" text-[#000] text-[20px] leading-[32px] tracking-[2px] py-[16px] px-[32px] rounded-[43px] bg-[#24deaa]"
            onClick={() => window.location.href = 'https://loop2earn.com'}
          >
            What We're Speeding Up
          </div>
        </div>
      </div>
      {/* second part */}
      <div className=" home-second mt-[69px] mb-[115px] w-[80vw] flex flex-col gap-[72px] items-center ">
        <div className=" mt-[69px] w-[428px] flex flex-col gap-[17px] items-center ">
          <h2 className=" text-[32px] leading-[150%] tracking-[3.2px] ">
            Accelerated projects
          </h2>
          <p className=" font-normal leading-[150%] tracking-[1.6px] ">
            We SEND your aspirations into competitive real world achievements
          </p>
        </div>
        <ul className=" flex gap-[48px] items-center justify-center ">
          <img src={homeLogo1} alt="" />
          <img src={homeLogo2} alt="" />
          <img src={homeLogo3} alt="" />
          <img src={homeLogo4} alt="" />
        </ul>
        <img className=" w-full " src={line} alt="" />
      </div>
      {/* three part */}
      <div className=" home-three w-full ">
        <div className=" title mb-[136px] ">
          <h2>our vision</h2>
          <h1>of the future</h1>
        </div>
        <div className=" flex flex-col gap-[180px] items-center mb-[140px] ">
          <div className=" vision-item flex justify-center items-center gap-[92px] ">
            <img className="w-[368px] h-[368px] -xs:h-auto" src={vision1} alt="" />
            <div className=" flex flex-col text-left gap-[24px] w-[549px] ">
              <p className=" text-[24px] leading-[150%] tracking-[2.4px] ">
                Harnessing the Power of AI
              </p>
              <p className=" text-[14px] leading-[150%] tracking-[1.4px] ">
                At SEND, we acknowledge the increasing importance of AI in
                generating and sharing customer insights, while also recognising
                the need for flexibility to cater to individual company
                differences and diverse customer needs. Our tech team enables
                you to build seamlessly with AI, utilising its suggestions to
                review, refine, and make personalised changes to meet your
                specific requirements.
              </p>
            </div>
          </div>
          <div className=" vision-item flex justify-center items-center gap-[92px] ">
            <div className=" flex flex-col text-left gap-[24px] w-[549px] ">
              <p className=" text-[24px] leading-[150%] tracking-[2.4px] ">
                Real World Assets
              </p>
              <p className=" text-[14px] leading-[150%] tracking-[1.4px] ">
                SEND is leading the way in leveraging web3 technology to
                tokenize real-world assets, working with property developers in
                New Zealand to offer fractionalized ownership and democratize
                access to traditionally illiquid assets in the Web3 space
              </p>
            </div>
            <img className="w-[368px] h-[368px] -xs:h-auto" src={vision2} alt="" />
          </div>
        </div>
      </div>
      {/* four part */}
      <div className=" home-four relative pt-[45px] pb-[138px] w-full overflow-hidden flex justify-center ">
        <ul className=" hidden gap-[15px] justify-center items-center ">
          <li
            style={{ background: trans == 0 ? "#24DEAA" : "#4E4E4E" }}
            className=" w-[10px] h-[10px] rounded-full "
            onClick={() => setTrans(0)}
          ></li>
          <li
            style={{ background: trans == 1 ? "#24DEAA" : "#4E4E4E" }}
            className=" w-[10px] h-[10px] rounded-full "
            onClick={() => setTrans(1)}
          ></li>
          <li
            style={{ background: trans == 2 ? "#24DEAA" : "#4E4E4E" }}
            className=" w-[10px] h-[10px] rounded-full "
            onClick={() => setTrans(2)}
          ></li>
          <li
            style={{ background: trans == 3 ? "#24DEAA" : "#4E4E4E" }}
            className=" w-[10px] h-[10px] rounded-full "
            onClick={() => setTrans(3)}
          ></li>
        </ul>
        <div className="">
          <div
            style={{
              transform:
                screen <= 730
                  ? trans == 0
                    ? "translateX(0)"
                    : trans == 1
                    ? "translateX(-105vw)"
                    : trans == 2
                    ? "translateX(-210vw)"
                    : "translateX(-315vw)"
                  : trans == 0
                  ? "translateX(0)"
                  : trans == 1
                  ? "translateX(-75vw)"
                  : trans == 2
                  ? "translateX(-150vw)"
                  : "translateX(-225vw)",
            }}
            className=" w-[70vw] flex items-center gap-[5vw]"
          >
            <img className=" w-[70vw] " src={loop1} alt="" />
            <img className=" w-[70vw] " src={loop2} alt="" />
            <img className=" w-[70vw] " src={loop3} alt="" />
            <img className=" w-[70vw] " src={loop4} alt="" />
          </div>
          <section className=" hidden w-[81px] justify-between items-center m-auto mt-[10px] ">
            <img src={greenLeft} alt="" onClick={transRed} />
            <img src={greenRight} alt="" onClick={transAdd} />
          </section>
        </div>
        <div className=" absolute top-[50%] translate-y-[-50%] m-auto w-[80vw] flex justify-between items-center ">
          <div className=" p-[16px] bg-[#fff] rounded-full " onClick={transRed}>
            <img src={left} alt="" />
          </div>
          <div className=" p-[16px] bg-[#fff] rounded-full " onClick={transAdd}>
            <img src={right} alt="" />
          </div>
        </div>
      </div>
      {/* five part */}
      <div className=" home-five relative w-full pl-[13vw] pr-[5vw] pt-[80px] pb-[116px] bg-[#fff] text-[#000] flex flex-col items-start ">
        <img
          className=" absolute left-0 bottom-[-61px] "
          src={prefessionalsbg}
          alt=""
        />
        <hgroup className=" text-[32px] leading-[150%] tracking-[3.2px] mb-[25px] ">
          web3 professionals
        </hgroup>
        <p className=" w-[428px] text-left font-normal leading-[150%] tracking-[1.6px] mb-[50px] ">
          We at SEND speed track your innovative technology organisation through
          a mulit-facet approach to enhance your time to market and give you
          that competitive edge all startups need to succeed
        </p>
        <div className=" flex justify-between w-full ">
          <img src={prefessionals} className="xl:w-[520px] xl:h-[580px] rounded-xl" alt="" />
          <div className=" flex flex-col items-start ">
            <section className="title">
              <h2 className=" leading-[150%] ">Mr M Armstrong</h2>
            </section>
            <p className=" text-[24px] leading-[150%] tracking-[2.4px] mb-[33px] ">
              Founder of SEND.
            </p>
            <p className=" w-[428px] font-normal leading-[150%] tracking-[1.6px] text-left mb-[57px] ">
              Since diving full-time into the web3 realm in 2017, Matt has played a pivotal role in the nurturing and flourishing of over a dozen projects. His expertise spans incubation, acceleration, advisory roles, and spearheading early rounds of fundraising
            </p>
            {/* <div className=" w-full flex justify-center items-center gap-[22px] ">
              <div className=" p-[16px] rounded-full bg-[#000] ">
                <img src={whiteLeft} alt="" />
              </div>
              <div className=" p-[16px] rounded-full bg-[#000] ">
                <img src={whiteRight} alt="" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* six part */}
      <div className=" home-six-desk pt-[169px] pb-[142px] flex flex-col gap-[52px] ">
        <ul className=" flex gap-[32px] text-left ">
          <li className=" w-[335px] ">
            <hgroup className="title">72+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Projects invested in
            </p>
          </li>
          <li className=" w-[335px] ">
            <hgroup className="title">2m +</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Capital deployed
            </p>
          </li>
          <li className=" w-[335px] ">
            <hgroup className="title">250+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Network of investors and influencers
            </p>
          </li>
        </ul>
        <ul className=" flex gap-[32px] text-left ">
          <li className=" w-[335px] ">
            <hgroup className="title">5M+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Raised for our projects
            </p>
          </li>
          <li className=" w-[335px] ">
            <hgroup className="title">100K+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Community growth generated
            </p>
          </li>
          <li className=" w-[335px] ">
            <hgroup className="title">6M+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Audience with our influencer partners
            </p>
          </li>
        </ul>
      </div>
      {/* six part */}
      <div className=" home-six-mobile hidden pt-[169px] pb-[142px] flex-col gap-[40px] ">
        <ul className=" flex gap-[32px] text-left ">
          <li className=" w-[335px] ">
            <hgroup className="title">72+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Projects invested in
            </p>
          </li>
          <li className=" w-[335px] ">
            <hgroup className="title">2m +</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Capital deployed
            </p>
          </li>
        </ul>
        <ul className=" flex gap-[32px] text-left ">
          <li className=" w-[335px] ">
            <hgroup className="title">250+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Network of investors and influencers
            </p>
          </li>
          <li className=" w-[335px] ">
            <hgroup className="title">5M+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Raised for our projects
            </p>
          </li>
        </ul>
        <ul className=" flex gap-[32px] text-left ">
          <li className=" w-[335px] ">
            <hgroup className="title">100K+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Community growth generated
            </p>
          </li>
          <li className=" w-[335px] ">
            <hgroup className="title">6M+</hgroup>
            <p className=" text-[24px] font-normal capitalize ">
              Audience with our influencer partners
            </p>
          </li>
        </ul>
      </div>
      <img src={line} alt="" />
      {/* seven part */}
      <div className=" home-seven w-full flex flex-col gap-[94px] pt-[127px] pb-[212px] ">
        <div className=" title ml-0 ">
          <h2>Industries</h2>
          <h1>We are working with</h1>
        </div>
        <div className=" m-auto flex flex-col w-[1072px] items-start ">
          <main className=" flex ">
            <TableItem content={industries.gaming} />
            <TableItem content={industries.nfts} />
            <TableItem content={industries.ai} />
          </main>
          <main className=" flex ">
            <TableItem content={industries.rwa} />
            <TableItem content={industries.web3} />
            <TableItem content={industries.personal} />
          </main>
        </div>
      </div>
      {/* eight part */}
      <div id="about" className=" home-eight service w-full flex flex-col gap-[94px] pt-[127px] pb-[212px] ">
        <div className=" title ml-0 mb-[82px] ">
          <h2>WHAT WE CAN DO</h2>
          <h1>FOR YOU</h1>
        </div>
        <div className=" relative w-[1072px] py-[43px] pl-[50px] pr-[190px] mb-[160px] rounded-[33px] bg-[#0f100f] m-auto ">
          <img
            className=" absolute right-[20px] top-[-106px] "
            src={ball}
            alt=""
          />
          <div className=" flex gap-[32px] flex-wrap ">
            {tag.map((item, i) => (
              <div
                key={i}
                className=" text-[#000] font-normal leading-[150%] tracking-[1.6px] text-center py-[10px] px-[32px] border-[1px] border-[#f3f3f3] rounded-[35px] bg-[#fff] whitespace-nowrap "
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className=" m-auto flex flex-col gap-[32px] items-start ">
          <main className=" flex gap-[10px] ">
            <CardItem content={service.one} />
            <CardItem content={service.two} />
            <CardItem content={service.three} />
          </main>
          <main className=" flex gap-[10px] ">
            <CardItem content={service.four} />
            <CardItem content={service.five} />
            <CardItem content={service.six} />
          </main>
        </div>
      </div>
      {/* night part */}
      <div id="partners" className=" home-night pt-[117px] pb-[154px] bg-[#232323] w-full ">
        <div className="  m-auto flex flex-col w-[1072px] items-center gap-[50px] ">
          <hgroup className=" text-[32px] leading-[150%] tracking-[3.2px] ">
            Ecosystem accelerator partners
          </hgroup>
          <div className=" w-full flex justify-between items-center ">
            <img className=" w-[40px] h-[40px] " src={whiteLeft} alt="" />
            <div style={{flexWrap:screen<900?'wrap':'nowrap'}} className=" flex gap-[72px] justify-center items-center ">
              <p style={{width:screen<900?'45%':'22%'}} className=" flex justify-center items-center "><img src={partner1} alt="" /></p>
              <p style={{width:screen<900?'45%':'22%'}} className=" flex justify-center items-center "><img src={partner2} alt="" /></p>
              <p style={{width:screen<900?'45%':'22%'}} className=" flex justify-center items-center "><img src={partner3} alt="" /></p>
              <p style={{width:screen<900?'45%':'22%'}} className=" flex justify-center items-center "><img src={partner4} alt="" /></p>
            </div>
            <img className=" w-[40px] h-[40px] " src={whiteRight} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
